import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const LogBookSetup = () => {
  return (
    <Layout>
      <Seo
        title="LogBook Setup"
        description={`Welcome to the Synkli Mobile App Trip Log Book setup guide.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`log-book-setup`} />
    </Layout>
  )
}

export default LogBookSetup
